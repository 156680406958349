@font-face {
    font-family: HKGrotesk;
    src: local(HKGrotesk-Bold), url(./fonts/HKGrotesk-Bold.otf) format('woff'),
        url(./fonts/HKGrotesk-Bold.otf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: HKGrotesk;
    src: local(HKGrotesk-Medium),
        url(./fonts/HKGrotesk-Medium.otf) format('woff'),
        url(./fonts/HKGrotesk-Medium.otf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

body,
input,
output,
select,
textarea {
    margin: 0;
    font-family: HKGrotesk;
    color: black;
}

button {
    cursor: pointer;
}

h1 {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 2.75rem;
    font-family: HKGrotesk;
}

h2 {
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 1.788rem;
    font-family: HKGrotesk;
}

h3 {
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 1.513rem;
    font-family: HKGrotesk;
    color: #6f6e6e;
}

h4 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.375rem;
    font-family: HKGrotesk;
}

img {
    vertical-align: middle;
}

@media screen and (max-width: 567px) {
    h1 {
        font-weight: bold;
        font-size: 2rem;
        font-family: HKGrotesk;
    }
}
