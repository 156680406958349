.react-calendar {
    width: 19.375rem;
    height: 20rem;
    flex-grow: 0;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    background: white;
}

.react-calendar__navigation__label__labelText {
    font-family: HKGrotesk;
    font-size: 1.25rem;
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    /* outline: none; */
}

.react-calendar__tile--rangeEnd button {
    margin: 0;
    border: 0;
    /* outline: none; */
    border: 2px #7da5da solid;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    /* min-width: 44px; */
    background: none;
}

.react-calendar__navigation__prev-button::before {
    content: url(/src/images/ic-chevron_left.svg);
    color: transparent;
    background-color: white;
    width: 15px;
    height: 15px;
    border: none;
}
.react-calendar__navigation__prev-button {
    color: transparent;
}

.react-calendar__navigation__next-button::before {
    content: url(/src/images/ic-chevron_right.svg);
}

.react-calendar__navigation__next-button {
    color: transparent;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    overflow: hidden;
    flex-grow: 0;
    font-family: HKGrotesk;
    font-size: 0.875rem;
    font-weight: normal;
    color: #6f6e6e;
    padding-bottom: 1rem;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

abbr {
    text-decoration: none;
}

.react-calendar__month-view__days__day {
    overflow: hidden;
    flex-grow: 0;
    font-family: HKGrotesk;
    font-size: 0.875rem;
    font-weight: normal;
    color: #6f6e6e;
    padding-bottom: 1rem;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
}
.react-calendar__tile:enabled:hover {
    color: black;
}

.react-calendar__tile--now {
    /* color: #675e9f; */
    color: #7da5da;
}

.react-calendar__tile--active {
    background: #f3f2f2;
    color: #313030;
}

.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeStart {
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 5px 0 rgba(103, 94, 159);
    z-index: 1;
}
